import React from 'react'
import Typography from '@material-ui/core/Typography'

const Titular = ({texto, color, tipo}) => (
  <div>
    <Typography variant={tipo ? tipo : 'h3'} color={color} gutterBottom>
      {texto}
    </Typography>
  </div>
)

export default Titular
