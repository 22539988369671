import React from 'react'
import EspaciadorVertical from './espaciador-vertical'
import Grid from '@material-ui/core/Grid'


const Minipaginas = ({paddingSuperior, paddingInferior, children}) => (
  <div>
    <EspaciadorVertical altura={paddingSuperior ? paddingSuperior : 38} />
    <Grid container spacing={2} justify="center" alignItems="flex-start">
      {children}
    </Grid>
    <EspaciadorVertical altura={paddingInferior ? paddingInferior : 0} />
  </div>
)
export default Minipaginas
